import React, { useEffect, Suspense } from 'react';
import './scss/style.scss'; //sass file contains all sass for application
import { Routes, Route } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import AOS from "aos";
import { Spinner } from 'react-bootstrap';
library.add(fab, fas, far)

const Layout = React.lazy(() => import('./components/layout/layout'));

const Loader = () => {
	return (
		<>
            <div className='d-flex justify-content-center vh-100 align-items-center flex-column'>
                <div className=''>
                    <Spinner animation="border" variant="primary" />
                </div>
            </div>
		</>
	)
}

const App = () => {
	useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path="*" element={<Layout />} />
			</Routes>
		</Suspense>
	);
}


export default App;
